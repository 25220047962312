/*
 * This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/.
 *
 * OpenCRVS is also distributed under the terms of the Civil Registration
 * & Healthcare Disclaimer located at http://opencrvs.org/license.
 *
 * Copyright (C) The OpenCRVS Authors located at https://github.com/opencrvs/opencrvs-core/blob/master/AUTHORS.
 */

import { useEffect } from 'react'

export const useTimeout = (
  callback: () => void,
  timeout = 0,
  trigger = false
) => {
  useEffect(() => {
    let timeoutReference: NodeJS.Timeout | undefined = undefined

    if (trigger) timeoutReference = setTimeout(callback, timeout)

    return () => {
      if (timeoutReference) clearTimeout(timeoutReference)
    }
  }, [callback, timeout, trigger])
}
