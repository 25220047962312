/*
 * This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/.
 *
 * OpenCRVS is also distributed under the terms of the Civil Registration
 * & Healthcare Disclaimer located at http://opencrvs.org/license.
 *
 * Copyright (C) The OpenCRVS Authors located at https://github.com/opencrvs/opencrvs-core/blob/master/AUTHORS.
 */
import { countryMessages as messages } from '@client/i18n/messages'
import { MessageDescriptor } from 'react-intl'

type CountryItem = { value: string; label: MessageDescriptor }

export const countries = [
  { value: 'AFG', label: messages.AFG },
  { value: 'ALA', label: messages.ALA },
  { value: 'ALB', label: messages.ALB },
  { value: 'DZA', label: messages.DZA },
  { value: 'ASM', label: messages.ASM },
  { value: 'AND', label: messages.AND },
  { value: 'AGO', label: messages.AGO },
  { value: 'AIA', label: messages.AIA },
  { value: 'ATA', label: messages.ATA },
  { value: 'ATG', label: messages.ATG },
  { value: 'ARG', label: messages.ARG },
  { value: 'ARM', label: messages.ARM },
  { value: 'ABW', label: messages.ABW },
  { value: 'AUS', label: messages.AUS },
  { value: 'AUT', label: messages.AUT },
  { value: 'AZE', label: messages.AZE },
  { value: 'BHS', label: messages.BHS },
  { value: 'BHR', label: messages.BHR },
  { value: 'BGD', label: messages.BGD },
  { value: 'BRB', label: messages.BRB },
  { value: 'BLR', label: messages.BLR },
  { value: 'BEL', label: messages.BEL },
  { value: 'BLZ', label: messages.BLZ },
  { value: 'BEN', label: messages.BEN },
  { value: 'BMU', label: messages.BMU },
  { value: 'BTN', label: messages.BTN },
  { value: 'BOL', label: messages.BOL },
  { value: 'BES', label: messages.BES },
  { value: 'BIH', label: messages.BIH },
  { value: 'BWA', label: messages.BWA },
  { value: 'BVT', label: messages.BVT },
  { value: 'BRA', label: messages.BRA },
  { value: 'IOT', label: messages.IOT },
  { value: 'VGB', label: messages.VGB },
  { value: 'BRN', label: messages.BRN },
  { value: 'BGR', label: messages.BGR },
  { value: 'BFA', label: messages.BFA },
  { value: 'BDI', label: messages.BDI },
  { value: 'CPV', label: messages.CPV },
  { value: 'KHM', label: messages.KHM },
  { value: 'CMR', label: messages.CMR },
  { value: 'CAN', label: messages.CAN },
  { value: 'CYM', label: messages.CYM },
  { value: 'CAF', label: messages.CAF },
  { value: 'TCD', label: messages.TCD },
  { value: 'CHL', label: messages.CHL },
  { value: 'CHN', label: messages.CHN },
  { value: 'HKG', label: messages.HKG },
  { value: 'MAC', label: messages.MAC },
  { value: 'CXR', label: messages.CXR },
  { value: 'CCK', label: messages.CCK },
  { value: 'COL', label: messages.COL },
  { value: 'COM', label: messages.COM },
  { value: 'COG', label: messages.COG },
  { value: 'COK', label: messages.COK },
  { value: 'CRI', label: messages.CRI },
  { value: 'CIV', label: messages.CIV },
  { value: 'HRV', label: messages.HRV },
  { value: 'CUB', label: messages.CUB },
  { value: 'CUW', label: messages.CUW },
  { value: 'CYP', label: messages.CYP },
  { value: 'CZE', label: messages.CZE },
  { value: 'PRK', label: messages.PRK },
  { value: 'COD', label: messages.COD },
  { value: 'DNK', label: messages.DNK },
  { value: 'DJI', label: messages.DJI },
  { value: 'DMA', label: messages.DMA },
  { value: 'DOM', label: messages.DOM },
  { value: 'ECU', label: messages.ECU },
  { value: 'EGY', label: messages.EGY },
  { value: 'SLV', label: messages.SLV },
  { value: 'GNQ', label: messages.GNQ },
  { value: 'ERI', label: messages.ERI },
  { value: 'EST', label: messages.EST },
  { value: 'SWZ', label: messages.SWZ },
  { value: 'ETH', label: messages.ETH },
  /*
   * Include imaginary Farajaland country to country lists for demo environments
   */
  window.config.COUNTRY === 'FAR'
    ? { value: 'FAR', label: messages.FAR }
    : null,
  { value: 'FLK', label: messages.FLK },
  { value: 'FRO', label: messages.FRO },
  { value: 'FJI', label: messages.FJI },
  { value: 'FIN', label: messages.FIN },
  { value: 'FRA', label: messages.FRA },
  { value: 'GUF', label: messages.GUF },
  { value: 'PYF', label: messages.PYF },
  { value: 'ATF', label: messages.ATF },
  { value: 'GAB', label: messages.GAB },
  { value: 'GMB', label: messages.GMB },
  { value: 'GEO', label: messages.GEO },
  { value: 'DEU', label: messages.DEU },
  { value: 'GHA', label: messages.GHA },
  { value: 'GIB', label: messages.GIB },
  { value: 'GRC', label: messages.GRC },
  { value: 'GRL', label: messages.GRL },
  { value: 'GRD', label: messages.GRD },
  { value: 'GLP', label: messages.GLP },
  { value: 'GUM', label: messages.GUM },
  { value: 'GTM', label: messages.GTM },
  { value: 'GGY', label: messages.GGY },
  { value: 'GIN', label: messages.GIN },
  { value: 'GNB', label: messages.GNB },
  { value: 'GUY', label: messages.GUY },
  { value: 'HTI', label: messages.HTI },
  { value: 'HMD', label: messages.HMD },
  { value: 'VAT', label: messages.VAT },
  { value: 'HND', label: messages.HND },
  { value: 'HUN', label: messages.HUN },
  { value: 'ISL', label: messages.ISL },
  { value: 'IND', label: messages.IND },
  { value: 'IDN', label: messages.IDN },
  { value: 'IRN', label: messages.IRN },
  { value: 'IRQ', label: messages.IRQ },
  { value: 'IRL', label: messages.IRL },
  { value: 'IMN', label: messages.IMN },
  { value: 'ISR', label: messages.ISR },
  { value: 'ITA', label: messages.ITA },
  { value: 'JAM', label: messages.JAM },
  { value: 'JPN', label: messages.JPN },
  { value: 'JEY', label: messages.JEY },
  { value: 'JOR', label: messages.JOR },
  { value: 'KAZ', label: messages.KAZ },
  { value: 'KEN', label: messages.KEN },
  { value: 'KIR', label: messages.KIR },
  { value: 'KWT', label: messages.KWT },
  { value: 'KGZ', label: messages.KGZ },
  { value: 'LAO', label: messages.LAO },
  { value: 'LVA', label: messages.LVA },
  { value: 'LBN', label: messages.LBN },
  { value: 'LSO', label: messages.LSO },
  { value: 'LBR', label: messages.LBR },
  { value: 'LBY', label: messages.LBY },
  { value: 'LIE', label: messages.LIE },
  { value: 'LTU', label: messages.LTU },
  { value: 'LUX', label: messages.LUX },
  { value: 'MDG', label: messages.MDG },
  { value: 'MWI', label: messages.MWI },
  { value: 'MYS', label: messages.MYS },
  { value: 'MDV', label: messages.MDV },
  { value: 'MLI', label: messages.MLI },
  { value: 'MLT', label: messages.MLT },
  { value: 'MHL', label: messages.MHL },
  { value: 'MTQ', label: messages.MTQ },
  { value: 'MRT', label: messages.MRT },
  { value: 'MUS', label: messages.MUS },
  { value: 'MYT', label: messages.MYT },
  { value: 'MEX', label: messages.MEX },
  { value: 'FSM', label: messages.FSM },
  { value: 'MCO', label: messages.MCO },
  { value: 'MNG', label: messages.MNG },
  { value: 'MNE', label: messages.MNE },
  { value: 'MSR', label: messages.MSR },
  { value: 'MAR', label: messages.MAR },
  { value: 'MOZ', label: messages.MOZ },
  { value: 'MMR', label: messages.MMR },
  { value: 'NAM', label: messages.NAM },
  { value: 'NRU', label: messages.NRU },
  { value: 'NPL', label: messages.NPL },
  { value: 'NLD', label: messages.NLD },
  { value: 'NCL', label: messages.NCL },
  { value: 'NZL', label: messages.NZL },
  { value: 'NIC', label: messages.NIC },
  { value: 'NER', label: messages.NER },
  { value: 'NGA', label: messages.NGA },
  { value: 'NIU', label: messages.NIU },
  { value: 'NFK', label: messages.NFK },
  { value: 'MNP', label: messages.MNP },
  { value: 'NOR', label: messages.NOR },
  { value: 'OMN', label: messages.OMN },
  { value: 'PAK', label: messages.PAK },
  { value: 'PLW', label: messages.PLW },
  { value: 'PAN', label: messages.PAN },
  { value: 'PNG', label: messages.PNG },
  { value: 'PRY', label: messages.PRY },
  { value: 'PER', label: messages.PER },
  { value: 'PHL', label: messages.PHL },
  { value: 'PCN', label: messages.PCN },
  { value: 'POL', label: messages.POL },
  { value: 'PRT', label: messages.PRT },
  { value: 'PRI', label: messages.PRI },
  { value: 'QAT', label: messages.QAT },
  { value: 'KOR', label: messages.KOR },
  { value: 'MDA', label: messages.MDA },
  { value: 'REU', label: messages.REU },
  { value: 'ROU', label: messages.ROU },
  { value: 'RUS', label: messages.RUS },
  { value: 'RWA', label: messages.RWA },
  { value: 'BLM', label: messages.BLM },
  { value: 'SHN', label: messages.SHN },
  { value: 'KNA', label: messages.KNA },
  { value: 'LCA', label: messages.LCA },
  { value: 'MAF', label: messages.MAF },
  { value: 'SPM', label: messages.SPM },
  { value: 'VCT', label: messages.VCT },
  { value: 'WSM', label: messages.WSM },
  { value: 'SMR', label: messages.SMR },
  { value: 'STP', label: messages.STP },
  { value: 'SAU', label: messages.SAU },
  { value: 'SEN', label: messages.SEN },
  { value: 'SRB', label: messages.SRB },
  { value: 'SYC', label: messages.SYC },
  { value: 'SLE', label: messages.SLE },
  { value: 'SGP', label: messages.SGP },
  { value: 'SXM', label: messages.SXM },
  { value: 'SVK', label: messages.SVK },
  { value: 'SVN', label: messages.SVN },
  { value: 'SLB', label: messages.SLB },
  { value: 'SOM', label: messages.SOM },
  { value: 'ZAF', label: messages.ZAF },
  { value: 'SGS', label: messages.SGS },
  { value: 'SSD', label: messages.SSD },
  { value: 'ESP', label: messages.ESP },
  { value: 'LKA', label: messages.LKA },
  { value: 'PSE', label: messages.PSE },
  { value: 'SDN', label: messages.SDN },
  { value: 'SUR', label: messages.SUR },
  { value: 'SJM', label: messages.SJM },
  { value: 'SWE', label: messages.SWE },
  { value: 'CHE', label: messages.CHE },
  { value: 'SYR', label: messages.SYR },
  { value: 'TJK', label: messages.TJK },
  { value: 'THA', label: messages.THA },
  { value: 'MKD', label: messages.MKD },
  { value: 'TLS', label: messages.TLS },
  { value: 'TGO', label: messages.TGO },
  { value: 'TKL', label: messages.TKL },
  { value: 'TON', label: messages.TON },
  { value: 'TTO', label: messages.TTO },
  { value: 'TUN', label: messages.TUN },
  { value: 'TUR', label: messages.TUR },
  { value: 'TKM', label: messages.TKM },
  { value: 'TCA', label: messages.TCA },
  { value: 'TUV', label: messages.TUV },
  { value: 'UGA', label: messages.UGA },
  { value: 'UKR', label: messages.UKR },
  { value: 'ARE', label: messages.ARE },
  { value: 'GBR', label: messages.GBR },
  { value: 'TZA', label: messages.TZA },
  { value: 'UMI', label: messages.UMI },
  { value: 'USA', label: messages.USA },
  { value: 'VIR', label: messages.VIR },
  { value: 'URY', label: messages.URY },
  { value: 'UZB', label: messages.UZB },
  { value: 'VUT', label: messages.VUT },
  { value: 'VEN', label: messages.VEN },
  { value: 'VNM', label: messages.VNM },
  { value: 'WLF', label: messages.WLF },
  { value: 'ESH', label: messages.ESH },
  { value: 'YEM', label: messages.YEM },
  { value: 'ZMB', label: messages.ZMB },
  { value: 'ZWE', label: messages.ZWE }
  // Remove potentially null country values (Farajaland)
].filter((country): country is CountryItem => Boolean(country))
