/*
 * This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/.
 *
 * OpenCRVS is also distributed under the terms of the Civil Registration
 * & Healthcare Disclaimer located at http://opencrvs.org/license.
 *
 * Copyright (C) The OpenCRVS Authors located at https://github.com/opencrvs/opencrvs-core/blob/master/AUTHORS.
 */
import { IAdvancedSearchParamState } from '@client/search/advancedSearch/reducer'

export const SET_ADVANCED_SEARCH_PARAM =
  'ADVANCED_SEARCH/SET_ADVANCED_SEARCH_PARAM'

type ModifyAdvancedSearchParamAction = {
  type: typeof SET_ADVANCED_SEARCH_PARAM
  payload: Partial<IAdvancedSearchParamState>
}

export const setAdvancedSearchParam = (
  paramsToUpdate: Partial<IAdvancedSearchParamState>
): ModifyAdvancedSearchParamAction => ({
  type: SET_ADVANCED_SEARCH_PARAM,
  payload: paramsToUpdate
})

export type AdvancedSearchParamActions = ModifyAdvancedSearchParamAction
